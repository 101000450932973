import { isEmpty } from 'lodash-es';
import moment from 'moment/moment';

import { images } from '@yojee/ui/map/components/assets';

interface Driver {
  id: number;
  location?: {
    lat: number;
    lng: number;
  };
  ongoing_tasks_count: number;
  location_updated_at: string;
  isPartnerWorker?: boolean;
}

export const getDriverMarkerIconUrl = (driver: Driver) => {
  if (driver.isPartnerWorker) {
    return images.partnerWorker;
  }

  const isNotOnDuty = driver.ongoing_tasks_count === 0 && isLocationDontUpdateInLast24Hours(driver);
  const isHavingOnGoingTask = driver.ongoing_tasks_count > 0;

  let icon = images.workerActive;
  if (isNotOnDuty) {
    icon = images.workerIsNotOnDuty;
  } else if (isHavingOnGoingTask) {
    icon = images.workerIsHavingOnGoingTask;
  }

  return icon;
};

export const getMarkerIcon = (iconUrl: string, scaledSize: object) => {
  return {
    url: iconUrl,
    scaledSize,
  };
};

const getDiffInHoursFromNowToLocationUpdatedAt = (driver: Driver) => {
  const locationUpdatedAt = moment(driver.location_updated_at);
  const now = moment();
  const duration = moment.duration(now.diff(locationUpdatedAt));

  return duration.asHours();
};

export const isLocationUpdatedInLast5Days = (driver: Driver) => {
  return getDiffInHoursFromNowToLocationUpdatedAt(driver) <= 24 * 5;
};

export const isLocationDontUpdateInLast24Hours = (driver: Driver) => {
  return getDiffInHoursFromNowToLocationUpdatedAt(driver) > 24;
};

export const getVisibleDrivers = (drivers: Driver[], workerFilter: number[]): Driver[] => {
  let visibleDrivers = drivers.filter(isLocationUpdatedInLast5Days);

  if (!isEmpty(workerFilter)) {
    visibleDrivers = drivers.filter((w) => workerFilter.includes(w.id));
  }

  return visibleDrivers.sort(
    (a, b) => moment(b.location_updated_at).valueOf() - moment(a.location_updated_at).valueOf()
  );
};

type WorkerIdDataMap = {
  [id: number]: Driver;
};

export const getUpdatedWorkerData = (
  workerIdDataMap: WorkerIdDataMap,
  workerId: number,
  newWorkerOnGoingTasksCount: number
): Driver => {
  const workerData = workerIdDataMap[workerId];

  if (workerData) return { ...workerData, ongoing_tasks_count: newWorkerOnGoingTasksCount };
  return workerData;
};

type Marker = {
  setMap: (map: unknown) => void;
  icon?: {
    url: string;
  };
  content?: HTMLAnchorElement;
  map?: unknown;
};

interface DriversMarkersMapping {
  [workerId: number]: Marker;
}

export const clearMarkers = (driversMarkersMapping: DriversMarkersMapping, workerId: number): void => {
  const marker = driversMarkersMapping[workerId];
  if (marker) {
    marker.map = null;
  }
};

type Popup = {
  setMap: (map: unknown) => void;
};

type DriversPopupsMapping = {
  [workerId: number]: Popup;
};

export const clearPopup = (driversPopupsMapping: DriversPopupsMapping, workerId: number): void => {
  const popup = driversPopupsMapping[workerId];
  if (popup) {
    popup.setMap(null);
  }
};

/**
 * We only re-update current marker when marker icon change (usually by location updated at)
 * @param currentMarker
 * @param currentDriverData
 */
export const shouldReUpdateCurrentDriverMarker = (currentMarker?: Marker, currentDriverData?: Driver) => {
  if (!currentMarker) return true;
  if (!currentDriverData) return false;

  const newWorkerIconUrl = getDriverMarkerIconUrl(currentDriverData);

  const iconUrl =
    currentMarker.icon?.url ??
    currentMarker.content?.getElementsByTagName('img')[0].src.replace(/^https?:\/\/[^/]+/, '');

  return iconUrl !== newWorkerIconUrl;
};

const getDriverLocationAsString = (driver: Driver): string => {
  let path = '';

  if (driver.location) {
    path += driver.location.lat + '' + driver.location.lng;
  }

  return path;
};

/**
 * For new driver marker only change when location or worker icon change
 * @param currentDriverData
 * @param newDriverData
 * @param disableAutoUpdateNewDriverMarkers
 */
export const shouldUpdateDriverMarker = (
  currentDriverData?: Driver,
  newDriverData?: Driver,
  disableAutoUpdateNewDriverMarkers?: boolean
) => {
  // If disableAutoUpdateNewDriverMarkers is true, we only update if updated workers exist and (location or worker icon) change
  if (disableAutoUpdateNewDriverMarkers) {
    return !!currentDriverData && !!newDriverData && isDriverDataUpdated(currentDriverData, newDriverData);
  }

  if (!currentDriverData) return true;
  if (!newDriverData) return false;

  return isDriverDataUpdated(currentDriverData, newDriverData);
};

const isDriverDataUpdated = (currentDriverData: Driver, newDriverData: Driver) => {
  if (getDriverMarkerIconUrl(currentDriverData) !== getDriverMarkerIconUrl(newDriverData)) return true;
  return getDriverLocationAsString(currentDriverData) !== getDriverLocationAsString(newDriverData);
};
