import './Map.scss';

import GoogleMapReact from 'google-map-react';
import React from 'react';
import { useSelector } from 'react-redux';

import { getMapData } from '@yojee/helpers/map-helper';
import * as zoomHelper from '@yojee/helpers/zoom-helper';

import addressLocatorMaker from './assets/address-locator-marker.svg';

const bootstrapURLKeys = {
  key: process.env.REACT_APP_SCOPED_GOOGLE_API_KEY,
  libraries: ['drawing', 'places'].join(','),
  id: 'explore-maps-script',
};

const mapOptions = (map, customOptions = {}) => {
  return {
    zoomControlOptions: {
      position: map.ControlPosition.RIGHT_BOTTOM, // as long as this is not set it works
      style: map.ZoomControlStyle.SMALL,
    },
    styles: undefined,
    scaleControl: true,
    streetViewControl: false,
    minZoom: zoomHelper.minZoom,
    maxZoom: zoomHelper.maxZoom,
    // keep this for reference: https://github.com/yojee/yojee-explore/pull/6218
    mapId: process.env.REACT_APP_GOOGLE_MAP_ID,
    ...customOptions,
  };
};

export const GoogleMap = ({
  onMapChange,
  handleGoogleMapApi,
  zoomControl = false,
  center = false,
  zoom = false,
  customMapOpts = {},
  customMapPinAddress = {},
}) => {
  const showMapInGreyScale = Object.hasOwn(customMapOpts, 'showInGreyScale') && customMapOpts.showInGreyScale === true;
  return (
    <GoogleMapReact
      options={(map) =>
        mapOptions(map, {
          zoomControl,
          ...(showMapInGreyScale
            ? {
                styles: [
                  {
                    stylers: [
                      {
                        saturation: -100,
                      },
                    ],
                  },
                ],
                ...customMapOpts,
              }
            : customMapOpts),
        })
      }
      yesIWantToUseGoogleMapApiInternals
      bootstrapURLKeys={bootstrapURLKeys}
      center={customMapPinAddress.center || center || zoomHelper.defaultCenter}
      zoom={zoom || zoomHelper.defaultZoom}
      onChange={onMapChange}
      onGoogleApiLoaded={handleGoogleMapApi}
      resetBoundsOnResize
    >
      {customMapPinAddress.lat && customMapPinAddress.lng && (
        <img
          className="marker"
          alt="location"
          src={addressLocatorMaker}
          lat={customMapPinAddress.lat}
          lng={customMapPinAddress.lng}
        />
      )}
    </GoogleMapReact>
  );
};

const GoogleMapWithBoundControl = ({
  onMapChange,
  handleGoogleMapApi,
  mapKey = null,
  zoomControl = false,
  customMapOpts = {},
  customMapPinAddress = {},
}) => {
  const { center, zoom } = useSelector((state) => getMapData(state, mapKey));

  return (
    <GoogleMap
      onMapChange={onMapChange}
      handleGoogleMapApi={handleGoogleMapApi}
      zoomControl={zoomControl}
      center={center}
      zoom={zoom}
      customMapOpts={customMapOpts}
      customMapPinAddress={customMapPinAddress}
    />
  );
};

export default React.memo(GoogleMapWithBoundControl);
